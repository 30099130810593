


























import { defineComponent, reactive, toRefs, watch } from "@vue/composition-api"
import ParsedSpaceObjectTable from "./ParsedSpaceObjectTable.vue"
import {
    closeDialog,
    DialogButtonType,
    errorDialog,
    infoDialog,
    setMessage,
    successDialog,
} from "@/services/DialogService"
import {
    spaceObjectTypeFormatter,
    setSpaceObject,
    SpaceObjectType,
} from "@/services/SpaceObjectsService"
import {
    collectErrorsFromParsedItems,
    KnownParsingError,
    openBookFromFile,
    ParsedSpaceObject,
    ParsingError,
} from "@/utilities/Parser"
import { HTTPError } from "ky"
import { PlaceSheetParser } from "@/utilities/PlaceSheetParser"
import { PlaceCustomPropertySheetParser } from "@/utilities/PlaceCustomPropertySheetParser"

export default defineComponent({
    name: "SpaceObjectImporter",
    components: {
        ParsedSpaceObjectTable,
        ImporterParseErrorTable: () =>
            import("../../components/ImporterParseErrorTable.vue"),
    },
    props: {
        type: {
            type: String as () => SpaceObjectType,
            required: true,
        },
    },
    setup(props) {
        const state = reactive({
            file: null as File | null,
            items: [] as ParsedSpaceObject[],
            errors: [] as ParsingError[],
        })

        async function parseFile(file: File) {
            try {
                infoDialog("解析中...", "", DialogButtonType.None)
                const book = await openBookFromFile(file)

                const placeParser = new PlaceSheetParser()
                const places = await placeParser.parse(book)
                const placeErrors = collectErrorsFromParsedItems(places)

                const customPropertyParser =
                    new PlaceCustomPropertySheetParser()
                const customProperties = await customPropertyParser.parse(book)
                const customPropertiesErrors =
                    collectErrorsFromParsedItems(customProperties)

                places.forEach((p) => {
                    p.customProperties = customProperties.filter(
                        (c) => c.id === p.id
                    )
                })

                state.items = places
                state.errors = placeErrors.concat(customPropertiesErrors)

                closeDialog()
                if (state.errors.length) {
                    errorDialog("解析發生錯誤", "請檢查匯入格式或資料是否正確")
                    return
                }
                successDialog("解析完成")
            } catch (error) {
                closeDialog()
                const msg =
                    error instanceof KnownParsingError
                        ? error.message
                        : "解析過程發生不明錯誤"

                errorDialog(msg)
            }
        }

        watch(
            () => state.file,
            async (file) => {
                if (!file) {
                    state.items = []
                    return
                }
                parseFile(file)
            }
        )

        async function startImport() {
            if (state.errors.length) {
                errorDialog("尚未處理解析錯誤，無法匯入")
                return
            }
            infoDialog("匯入中，請稍後", "", DialogButtonType.None)
            for (let i = 0; i < state.items.length; i++) {
                const item = state.items[i]
                try {
                    await setSpaceObject({
                        id: item.id,
                        parentId: item.parentId,
                        number: item.number,
                        name: item.name ?? "",
                        type: props.type,
                        mileage: item.mileage,
                        category: item.category,
                        coordinate: item.coordinate,
                        bimId: item.bimId,
                        labels: item.labels,
                        customProperties: item.customProperties?.map((p) => ({
                            name: p.name!,
                            value: p.value,
                        })),
                    })
                    setMessage(`${i} / ${state.items.length}`)
                } catch (error) {
                    closeDialog()

                    if (error instanceof HTTPError) {
                        if (
                            error.response.status >= 400 ||
                            error.response.status < 500
                        ) {
                            errorDialog(
                                `第${item.sourceRow}列 ID:${item.id} 匯入發生錯誤，請檢查匯入資料`,
                                `${await error.response.text()}`
                            )
                            return
                        }
                    }
                    errorDialog(
                        `第${item.sourceRow}列 ID:${item.id} 匯入發生不預期錯誤，請聯絡系統維護人員`,
                        `${error}`
                    )
                    return
                }
            }
            closeDialog()
            successDialog("匯入完成")
        }

        return {
            ...toRefs(state),
            spaceObjectTypeFormatter,
            startImport,
        }
    },
})
