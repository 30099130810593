import { ParsedItem } from "./Parser"
import { SheetParser } from "./SheetParser"

export interface PlaceCustomPropertyImportRow {
    ID?: string
    自定義屬性名稱?: string
    自定義屬性值?: string
}

export interface ParsedPlaceCustomProperty extends ParsedItem {
    id?: string
    name?: string
    value: string
}

export class PlaceCustomPropertySheetParser extends SheetParser<
    PlaceCustomPropertyImportRow,
    ParsedPlaceCustomProperty
> {
    sheetName = "空間物件自定義屬性"
    parseRow(
        row: PlaceCustomPropertyImportRow,
        payload: ParsedItem,
        addError: (col: string, err: string) => void
    ): ParsedPlaceCustomProperty {
        if (!row.ID) addError("ID", "缺漏 ID")
        if (!row.自定義屬性名稱) addError("自定義屬性值", "缺漏 自定義屬性值")
        return Object.assign(payload, {
            id: row.ID,
            name: row.自定義屬性名稱,
            value: row.自定義屬性值 ?? "",
        })
    }
}
