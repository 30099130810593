export interface Option<T> {
    value?: T
    error?: any
}
export function tryGet<T>(func: () => T): Option<T> {
    try {
        return {
            value: func()
        }
    } catch (error) {
        return {
            error
        }
    }
}

export async function tryGetAsync<T>(
    func: () => Promise<T>
): Promise<Option<T>> {
    try {
        return {
            value: await func()
        }
    } catch (error) {
        return {
            error
        }
    }
}
