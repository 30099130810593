import {
    Coordinate,
    Label,
    Mileage,
    MileageType,
    SpaceObjectCache,
} from "@/services/SpaceObjectsService"
import { checkId, ParsedItem, ParsedSpaceObject, tryParseFloat } from "./Parser"
import { SheetParser } from "./SheetParser"
import { tryGet } from "./Try"

export interface SpaceObjectImportRow {
    ID?: string
    編號?: string
    名稱?: string
    所在位置ID?: string
    分類?: string
    里程?: string
    座標?: string
    "BIM ID": string
    標籤?: string
}

export const SHEET_NAME = "空間物件清單"

export class PlaceSheetParser extends SheetParser<
    SpaceObjectImportRow,
    ParsedSpaceObject
> {
    sheetName = SHEET_NAME
    parseRow(
        row: SpaceObjectImportRow,
        payload: ParsedItem,
        addError: (col: string, err: string) => void
    ): ParsedSpaceObject {
        if (row.ID && !checkId(row.ID))
            addError("ID", "ID 必須為英文數字底線減號的組合,長度100以內")
        if (!row.編號) addError("編號", "缺漏 編號")
        if (!row.名稱) addError("名稱", "缺漏 名稱")

        const mileage: Mileage | undefined = (() => {
            if (!row.里程) return undefined
            if (row.里程.includes(",")) {
                const [startText, endText] = row.里程
                    .split(",")
                    .map((e) => e.trim())
                const start = tryParseFloat(startText)
                const end = tryParseFloat(endText)
                if (start === null || end === null) {
                    addError("里程", "里程解析失敗，格式應為12.3或12.3, 45.6")
                    return undefined
                }
                const result: Mileage = {
                    type: MileageType.Range,
                    road: null,
                    value: null,
                    start,
                    end,
                }
                return result
            } else {
                const value = tryParseFloat(row.里程)
                if (value === null) {
                    addError("里程", "里程解析失敗，格式應為12.3或12.3, 45.6")
                    return undefined
                }
                const result: Mileage = {
                    type: MileageType.Point,
                    road: null,
                    value,
                    start: null,
                    end: null,
                }
                return result
            }
        })()

        const { value: coordinate } = tryGet(() => {
            if (!row.座標) return null
            const [x, y] = row.座標.split(",")
            return <Coordinate>{
                x: parseFloat(x),
                y: parseFloat(y),
            }
        })
        const { value: labels } = tryGet(() => {
            if (!row.標籤) return []
            return row.標籤.split(",").map((label) => {
                const [name, value] = label.split(":")
                return <Label>{
                    name: name.trim(),
                    value: value.trim(),
                }
            })
        })
        if (!labels)
            addError("標籤", "標籤解析失敗,格式應為 key1:value1, key2:value2")

        return Object.assign(payload, {
            id: row.ID || undefined,
            number: row.編號 || undefined,
            name: row.名稱 || undefined,
            parentId: row.所在位置ID || undefined,
            location: undefined,
            mileageText: row.里程 || undefined,
            mileage,
            category: row.分類 || undefined,
            coordinate: coordinate ?? undefined,
            bimId: row["BIM ID"],
            labels,
        })
    }

    async postProcess(
        parsedItems: ParsedSpaceObject[]
    ): Promise<ParsedSpaceObject[]> {
        await SpaceObjectCache.updateByIds(
            parsedItems
                .map((item) => item.parentId)
                .filter((id) => !!id) as string[]
        )

        parsedItems.forEach((row) => {
            if (!row.parentId) return
            const match = SpaceObjectCache.get(row.parentId)
            if (!match) {
                row.errors.push({
                    col: "所在位置ID",
                    err: "無法對照到所在位置",
                })
                return
            }
            row.location = match
        })

        return parsedItems
    }
}
